<template>
  <v-card>
    <v-card-title> Add Location </v-card-title>
    <v-card-text>
      <h1 class="text-center black--text text-h5 text-uppercase">
        Location Information
      </h1>
      <validation-observer ref="location_observer" v-slot="{ invalid }">
        <form @submit.prevent="submit">
          <v-container>
            <v-row>
              <v-col md="6" sm="12" lg="6" xl="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Business Name"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.name"
                    :error-messages="errors"
                    label="Name"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.address"
                    :error-messages="errors"
                    label="Address"
                    required
                  ></v-text-field>
                </validation-provider>
                <country-region-data-dropdown
                  clearable
                  label="City"
                  v-model="formData.city"
                />
              </v-col>
              <!--  -->
              <v-col md="6" sm="12" lg="6" xl="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Zipcode"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.zipcode"
                    :error-messages="errors"
                    label="Zipcode"
                    required
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <v-text-field
                    dense
                    outlined
                    v-model="formData.catastro"
                    :error-messages="errors"
                    label="Numero de catastro"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text type="submit" :disabled="invalid"
              >Create</v-btn
            >
            <v-btn text color="red">Cancel</v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { required /*  digits, regex */ } from "vee-validate/dist/rules";
import CountryRegionDataDropdown from "../../components/common/CountryRegionDataDropdown.vue";
import { mapActions } from "vuex";
setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  data() {
    return {
      formData: {
        name: "",
        address: "",
        city: "",
        zipcode: "",
        catastro: "",
      },
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    CountryRegionDataDropdown,
  },
  methods: {
    ...mapActions(["createLocation"]),
    submit() {
      const self = this;
      const valid = self.$refs.location_observer.validate();
      if (!valid) return;
      self
        .createLocation(self.formData)
        .then(() => {
          self.formData = {
            name: "",
            address: "",
            city: "",
            zipcode: "",
            catastro: "",
          };
          self.$refs.location_observer.reset();
          self.showNotification({
            type: 1,
            text: 'Location created',
            lngKey: false,
          });
        })
        .catch((err) => {
            this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: "Error Creating the Location",
                text: err,
                toast: true,
                position: "bottom-end",
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: true,
            });
        })
        .finally(() => {
          self.closeNotifications(3);
        });
    },
  },
};
</script>

<style>
</style>